@import 'bootstrap/scss/bootstrap';

// Bootstrap override
.container {
  max-width: 920px;
}

.main {
  padding-top: 0.5rem;
  min-height: 90%;
  min-height: -webkit-calc(100% - 186px);
  min-height: -moz-calc(100% - 186px);
  min-height: calc(100vh - 56px - 56px);
}

// Custom styles
#carte {
  font-size: 0;
  width: 100%;
  margin-bottom: 1rem;
}

#station {
  margin-bottom: 0.5rem;
}

.start {
  color: #008a22;
}

.end {
  color: #e52228;
}

.map-tooltip {
  position: absolute;
  visibility: hidden;
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0.25rem;
  color: #3b3d45;
  top: 0;
}

@keyframes slide-enter {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  html:not(.no-sliding) [slide-enter],
  html:not(.no-sliding) .slide-enter,
  html:not(.no-sliding) .slide-enter-content > *:not(:has(.table-of-contents)) {
    --enter-stage: 0;
    --enter-step: 40ms;
    --enter-initial: 0ms;
    animation: slide-enter 1s both 1;
    animation-delay: calc(
      var(--enter-initial) + var(--enter-stage) * var(--enter-step)
    );
  }

  .slide-enter-content {
    $element: 100;
    @for $i from 1 to $element {
      > *:nth-child(#{$i}) {
        --enter-stage: #{$i} !important;
      }
    }
  }

  html:not(.no-sliding) [slide-enter],
  html:not(.no-sliding) .slide-enter,
  html:not(.no-sliding)
    .stations-slide-enter-content
    > *:not(:has(.table-of-contents)) {
    --enter-stage: 0;
    --enter-step: 200ms;
    --enter-initial: 0ms;
    animation: slide-enter 2s both 1;
    animation-delay: calc(var(--enter-initial) + 1 * var(--enter-step));
  }

  .stations-slide-enter-content {
    $element: 2;
    @for $i from 1 to $element {
      > *:nth-child(#{$i}) {
        --enter-stage: #{$i} !important;
      }
    }
  }
}
